<template>
  <div
    :class="
      `${$store.getters['display/getAside'] ? 'contents expanded' : 'contents'}`
    "
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :options="breadcrumbOptions" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-30">
          <div class="card">
            <div class="my-card-header color-dark fw-500 pt-3">
              <span class="float-left py-2">
                <span class="h4 px-3">
                  {{ t("filter", {}, { locale: getLocal }) }}
                </span>
              </span>
              <span class="float-right">
                <span class="max-w-px-200 my-dropdown">
                    <label for="startDate">
  {{ t("startDate", {}, { locale: getLocal }) }}
</label>
<input id="startDate" v-model="startDate" type="date" />
                </span>
                <span class="max-w-px-200 my-dropdown">
                    <label for="endDate">
  {{ t("endDate", {}, { locale: getLocal }) }}
</label>
<input id="endDate" v-model="endDate" type="date" />
                </span>
              </span>
            </div>
            <div class="card-body m-0 p-0">
              <div class="userDatatable global-shadow border-0 bg-white w-100">
                <!-- ------------------------------------ d-lg-block------------------------- -->
                <div class="table-responsive d-none d-xl-block">
                  <table class="table mb-0 table-borderless">
                    <thead>
                      <tr class="userDatatable-header">
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("report_date", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("sum_lotto", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="table_tbody">
                      <tr v-for="(e, index) in listData" :key="index">
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.report_date ? e.report_date : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.sum_lotto ? e.sum_lotto : "-" }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- ------------------------------------ end d-lg-block------------------------- -->
                <!-- ----------------------- size md -------------------- -->
                <div
                  class="
                      row
                      w-100
                      d-none d-md-block d-lg-block d-xl-none m-0
                      p-0
                    "
                >
                  <div class="col-12 mymd m-0 p-0">
                    <table class="table table-responsive table-borderless">
                      <!-- ------------------ head ------------------- -->
                      <thead>
                        <tr class="userDatatable-header">
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("id", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("Did", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("lottonumber", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("drawdateen", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("drawdateth", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th></th> -->
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("setnumber", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("Dnumber", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th></th> -->
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("lottorefnum", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("lottobarcode", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{
                                t(
                                  "lottobarcodetextapp",
                                  {},
                                  { locale: getLocal }
                                )
                              }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("price", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("image", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("isluckynum", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("prizedetail", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("prizemoney", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "sellinglottoid",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "reservinglottoid",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th></th> -->
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{
                                t("reserveexpiretime", {}, { locale: getLocal })
                              }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("stt", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("creat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("upat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th></th> -->
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("setstt", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("edit", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <!-- ----------------- body ------------------- -->
                      <tbody id="table_tbody">
                        <tr v-for="(e, index) in listData" :key="index">
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.id ? e.id : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.drawId ? e.drawId : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.lottoNumber ? e.lottoNumber : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.drawDateEn ? e.drawDateEn : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.drawDateTh ? e.drawDateTh : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <!-- <td></td> -->

                          <td>
                            <div class="row">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.setNumber ? e.setNumber : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.drawNumber ? e.drawNumber : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>

                          <!-- <td></td> -->

                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.lottoRefNumber ? e.lottoRefNumber : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{
                                e.lottoBarcodeRawData
                                  ? e.lottoBarcodeRawData
                                  : "-"
                              }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{
                                e.lottoBarcodeTextAppear
                                  ? e.lottoBarcodeTextAppear
                                  : "-"
                              }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.price ? e.price : "-" }}
                            </div>
                          </td>
                          <td>
                            <div
                              @click="showIMG(e)"
                              class="d-flex justify-content-center"
                            >
                              <img
                                v-if="e.image"
                                :src="e.image"
                                alt="img_uploaded"
                                class="img-size"
                              />
                              <div
                                v-else
                                class="userDatatable-content text-center"
                              >
                                -
                              </div>
                            </div>
                          </td>

                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.isLuckyNumber ? e.isLuckyNumber : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.prizeDetail ? e.prizeDetail : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.prizeMoney ? e.prizeMoney : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="ro">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.sellingLotteryId
                                      ? e.sellingLotteryId
                                      : "-"
                                  }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.reservingLotteryId
                                      ? e.reservingLotteryId
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>

                            <!-- </td>
                            <td> -->
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{
                                e.reserveExpireTime ? e.reserveExpireTime : "-"
                              }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.status ? e.status : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.created_at ? e.created_at : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.updated_at ? e.updated_at : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <!-- <td></td> -->
                          <td>
                            <div class="row text-center">
                              <div class="col-12 mb-1">
                                <button
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusOn(e, 'On Going')"
                                  class="btn-action btn-action-close"
                                >
                                  {{ t("ongoing", {}, { locale: getLocal }) }}
                                </button>
                              </div>
                              <div class="col-12 mb-1">
                                <button
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusClose(e, 'Cancel')"
                                  class="btn-action btn-action-delete"
                                >
                                  {{ t("cancel", {}, { locale: getLocal }) }}
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a v-if="$store.getters['auth/getUser']">
                              <a href="#" @click="editForm(e)" class="edit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path></svg
                              ></a>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size md -------------------- -->
                <!-- ----------------------- size sm -------------------- -->
                <div
                  class="
                      row
                      w-100
                      d-block d-sm-block d-md-none d-lg-none
                      m-0
                      p-0
                    "
                >
                  <div class="col-12 mymd w-100 m-0 p-0">
                    <table
                      class="table mb-0 mb-3"
                      v-for="(e, index) in listData"
                      :key="index"
                    >
                      <thead>
                        <tr class="userDatatable-header">
                          <th></th>
                        </tr>
                      </thead>

                      <tbody id="table_tbody">
                        <tr class="border_bottom">
                          <td class="p-0 m-0">
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("id", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 border p-3">
                                <div class="userDatatable-content text-center">
                                  {{ e.id ? e.id : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("Did", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.drawId ? e.drawId : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("lottonumber", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.lottoNumber ? e.lottoNumber : "-" }}
                                </div>
                              </div>
                            </div>

                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t(
                                          "drawdateen",
                                          {},
                                          { locale: getLocal }
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.drawDateEn ? e.drawDateEn : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t(
                                          "drawdateth",
                                          {},
                                          { locale: getLocal }
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.drawDateTh ? e.drawDateTh : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t("setnumber", {}, { locale: getLocal })
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.setNumber ? e.setNumber : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t("Dnumber", {}, { locale: getLocal })
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.drawNumber ? e.drawNumber : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t(
                                          "lottorefnum",
                                          {},
                                          { locale: getLocal }
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{
                                        e.lottoRefNumber
                                          ? e.lottoRefNumber
                                          : "-"
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{
                                        t(
                                          "lottobarcode",
                                          {},
                                          { locale: getLocal }
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{
                                        e.lottoBarcodeRawData
                                          ? e.lottoBarcodeRawData
                                          : "-"
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "lottobarcodetextapp",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.lottoBarcodeTextAppear
                                      ? e.lottoBarcodeTextAppear
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("price", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.price ? e.price : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("image", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div
                                  @click="showIMG(e)"
                                  class="d-flex justify-content-center"
                                >
                                  <img
                                    v-if="e.image"
                                    :src="e.image"
                                    alt="img_uploaded"
                                    class="img-size"
                                  />
                                  <div
                                    v-else
                                    class="userDatatable-content text-center"
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("isluckynum", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.isLuckyNumber ? e.isLuckyNumber : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("prizedetail", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.prizeDetail ? e.prizeDetail : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t("prizemoney", {}, { locale: getLocal })
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.prizeMoney ? e.prizeMoney : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "sellinglottoid",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.sellingLotteryId
                                      ? e.sellingLotteryId
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "reservinglottoid",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.reservingLotteryId
                                      ? e.reservingLotteryId
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>

                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{
                                    t(
                                      "reserveexpiretime",
                                      {},
                                      { locale: getLocal }
                                    )
                                  }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{
                                    e.reserveExpireTime
                                      ? e.reserveExpireTime
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("stt", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.status ? e.status : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{ t("creat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.created_at ? e.created_at : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                          userDatatable-title
                                          text-center
                                          m-0
                                        "
                                    >
                                      {{ t("upat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.updated_at ? e.updated_at : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 text-center">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("setstt", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 m-0 p-0 border">
                                <div class="row my-2">
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="SwalselectStatusOn(e, 'On Going')"
                                      class="btn-action btn-action-close"
                                    >
                                      {{
                                        t("ongoing", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="
                                        SwalselectStatusClose(e, 'Cancel')
                                      "
                                      class="btn-action btn-action-delete"
                                    >
                                      {{
                                        t("cancel", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1"></div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size sm -------------------- -->
                <Pagination
                  ref="PaginationRef"
                  :Count="count"
                  @PaginationReturnData="PaginationReturnData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalImg :srcImage="showIMGData" />
    <ModalEdit :editData="editData" :options="editOptions" />
  </div>
</template>

<script>
// @ is an alias to /src

import * as serviceAPI from "../services/API.service";
import * as serviceMain from "../services/main.service";
import * as constant from "../services/constant";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ModalImg from "@/components/Modal/ModalImg.vue";
import ModalEdit from "@/components/Modal/ModalEdit.vue";

export default {
  name: "DailyReport",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    Pagination,
    Breadcrumb,
    ModalImg,
    ModalEdit,
  },
  data: function() {
    const today = new Date().toISOString().substr(0, 10);
    return {
        startDate: today,
        endDate: today,
      breadcrumbOptions: {
        isShowAddLottery: false,
        isShowRefresh: false,
        isShowStartSearch: true,
        pageKey: "dailyreport",
        refresh: () => this.Refresh(),
        startSearch: () => this.startSearch(),
      },
      editOptions: {
        type: "ListLottery",
        modalKey: "editlotto",
        onFileChange: (event) => this.onFileChangeedit(event),
        sendEditData: (event) => this.sendEditData(event),
      },
      PaginationData: {},

      imgPath: constant.imageUrl,
      selectedOne: "",
      errors: [],
      accessData: [],
      accessDataCustomer: [],
      companyData: [],
      listData: [],
      selectedID: [],
      createData: [],
      editData: {
        lottoId: "",
        lottoNumber: "",
        setNumber: "",
        drawDateTh: "",
        drawDateEn: "",
        drawId: "",
        drawNumber: "",
        lottoRefNumber: "",
        lottoBarcodeRawData: "",
        lottoBarcodeTextAppear: "",
        price: "",
        image: "",
        IMAGESRC: "",
      },
      showIMGData: "",
    };
  },
  mounted() {
    this.showData();
  },
  methods: {
    async Refresh() {
      this.count = 0;
      this.onSearch = false;
      this.order = "drawNumber";
      this.dir = "ASC";
      this.status = "";
      this.drawId = "";
      this.drawDate = "";
      this.isLuckyNumber = "N";
      this.showData();
    },
    async startSearch() {
        // Log the values of startDate and endDate
    console.log('Start Date:', this.startDate);
    console.log('End Date:', this.endDate);
    
      this.count = 0;
      this.onSearch = true;
      this.showData();
    },
    PaginationReturnData(para_val) {
      this.PaginationData = para_val;

      this.showData();
    },

    async showData() {
      try {
        let data = {
          start: this.PaginationData.offset,
          length: this.PaginationData.limit,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "lotto/api/v1/getDailyReport",
          data,
          "auth"
        );
        console.log(getAPI);
        this.count = getAPI.data.recordsFiltered;
        this.listData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async showIMG(e) {
      this.showIMGData = e.image;
      $("#modal-image").modal("show");
    },
    async onFileChangeedit(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.editData.image = files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        this.editData.IMAGESRC = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },

    async SwalselectStatusOn(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusOn(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusCancel(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },

    async selectStatusOn(e) {
      try {
        let data = {
          lottoId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setLottoStatusToOnGoing",
          data,
          "auth"
        );
        this.editData = getAPI.data.data;
        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusCancel(e) {
      try {
        let data = {
          lottoId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setLottoStatusToCancel",
          data,
          "auth"
        );
        this.editData = getAPI.data.data;
        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async SwalDeleteUser(e) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.DeleteUser(e);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalCancel(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Cancel this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Cancel it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Close this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Close it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalDelete(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async DeleteUser(e) {
      try {
        let data = { LOANID: e.id };
        let getAPI = await serviceAPI.call_API(
          "delete",
          "loanagreement/crudloanagreement",
          data,
          "auth"
        );
        let getData = getAPI.data.data;
        this.Refresh();
        this.$swal.fire("Deleted!", "Your user has been deleted.", "success");
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async getAccessData() {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "get",
          "user/getaccessdata",
          data,
          "auth"
        );
        this.accessData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async getAccessDataCustomer() {
      try {
        let data = {
          mode: "all",
          ID: "1",
          OFFSET: "0",
          RWS: "0",
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "customer/crudcustomer",
          data,
          "auth"
        );
        this.accessDataCustomer = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async editForm(e) {
      $("#modal-edit").modal("show");
      this.editData = {
        lottoId: e.id,
        lottoNumber: e.lottoNumber,
        setNumber: e.setNumber,
        IMAGESRC: e.image,
        drawDateTh: e.drawDateTh,
        drawDateEn: e.drawDateEn,
        drawNumber: e.drawNumber,
        lottoRefNumber: e.lottoRefNumber,
        lottoBarcodeRawData: e.lottoBarcodeRawData,
        lottoBarcodeTextAppear: e.lottoBarcodeTextAppear,
        price: e.price,
      };
    },

    async sendEditData(e) {
      try {
        e.preventDefault();
        let form = new FormData();
        form.append("lottoId", this.editData.lottoId);
        form.append("lottoNumber", this.editData.lottoNumber);
        form.append("setNumber", this.editData.setNumber);
        form.append("drawDateTh", this.editData.drawDateTh);
        form.append("drawDateEn", this.editData.drawDateEn);
        form.append("drawNumber", this.editData.drawNumber);
        form.append("lottoRefNumber", this.editData.lottoRefNumber);
        form.append("lottoBarcodeRawData", this.editData.lottoBarcodeRawData);
        form.append(
          "lottoBarcodeTextAppear",
          this.editData.lottoBarcodeTextAppear
        );
        form.append("price", this.editData.price);
        form.append("image", this.editData.image);

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/editLotto",
          form
        );

        $("#modal-edit").modal("hide");
        this.editData.lottoId = "";
        this.editData.lottoNumber = "";
        this.editData.IMAGESRC = "";
        this.editData.setNumber = "";
        this.editData.drawDateTh = "";
        this.editData.drawDateEn = "";
        this.editData.drawNumber = "";
        this.editData.lottoRefNumber = "";
        this.editData.lottoBarcodeRawData = "";
        this.editData.lottoBarcodeTextAppear = "";
        this.editData.price = "";
        this.editData.image = "";

        let getData = getAPI.status.messageTH;
        serviceMain.showSuccessAlert(this, getData);
        this.Refresh();
      } catch (error) {
        $("#modal-edit").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },

    async checkForm(e) {
      e.preventDefault();

      if (
        this.createData.ID &&
        this.createData.BANKID &&
        this.createData.BANKNAME &&
        this.createData.BACCNO &&
        this.createData.BACCNA
      ) {
        this.sendCreateData();
        return;
      }

      this.errors = [];

      if (!this.createData.USERNAME) {
        this.errors.push("Username required.");
      }
      if (!this.createData.PASSWORD) {
        this.errors.push("Password required.");
      }
      if (!this.createData.FNAME) {
        this.errors.push("First Name required.");
      }
      if (!this.createData.LNAME) {
        this.errors.push("Last Name required.");
      }
      if (!this.createData.ROLE) {
        this.errors.push("Role required.");
      }
    },
    ckbox(para_id) {
      if (this.selectedID.includes(para_id)) {
        this.selectedID = this.selectedID.filter((e) => e !== para_id);
      } else {
        this.selectedID.push(para_id);
      }
    },

    uploadImageEdit(e) {
      let image = e.target.files[0];
      let FR = new FileReader();
      FR.onload = (ev) => {
        let getOringinalImg = ev.target.result;
        this.resizedataURLEdit(this, getOringinalImg, 100, 100);
      };
      FR.readAsDataURL(image);
    },
    resizedataURLEdit(_this, datas, wantedWidth, wantedHeight) {
      var img = document.createElement("img");
      img.onload = function() {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        console.log("dataURI:");
        console.log(dataURI);
      };
      img.src = datas;
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  computed: {
    getLocal: function() {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style scope>
.mymd table {
  border: 1px solid #ddd;
}
.table-responsive {
  overflow-y: auto;
  max-height: 70vh;
}
.btn-action-cancel {
  background-color: rgb(245, 221, 37);
}
.btn-action-close {
  background-color: orange;
}
.btn-action-delete {
  background-color: rgb(226, 80, 80);
}
.btn-action-view {
  background-color: rgb(3, 117, 238);
}
.btn-action {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  margin: 0px 5px;
}
.img-size {
  max-height: 100px;
}
</style>
